import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index"), //首页
  },
  {
    path: "/publicity",
    name: "publicity",
    component: () => import("@/views/publicity"), //宣传资料
  },
  {
    path: "/classify",
    name: "classify",
    component: () => import("@/views/classify"), //分类
  },
  {
    path: "/classifyTwo",
    name: "classifyTwo",
    component: () => import("@/views/classifyTwo"), //分类
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("@/views/schedule"), //分类
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router