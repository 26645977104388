<template>
  <!-- // @click="toPlay" -->
  <div id="app">
    <router-view> </router-view>
  </div>
</template>
<script>
// import minxin from "@/mixins/index.js"; // 引入minixn
// import { getPath } from "@/api/apiData";
export default {
  data() {
    return {
      video: "",
    };
  },
  // mixins: [minxin],
  mounted() {
    // this.getWechatConfig();
  },
  methods: {},
};
</script>
<style>
body,
html {
  height: 100%;
}
#app {
  height: 100%;
}
.van-field__label {
  color: black !important;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
body {
  /* background: #f2f2f2; */
  font-size: 14px !important;
  background: #EDF8F9;
}
.font1 {
  font-family: font1;
}
.font2 {
  font-family: font2;
}
.font3 {
  font-family: font3;
}
.absolute {
  position: absolute;
}
.rotate-circle,
.tmap-zoom-control,
.tmap-scale-control,
.logo-text {
  display: none;
}
.info_card {
  text-align: left;
  line-height: 2;
}
.info_card .title {
  font-weight: bold;
}
/* 背景 */
.bgColor {
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.align {
  align-items: center;
}
.centerj {
  justify-content: center;
}
.wrap {
  flex-wrap: wrap;
}
.nav1-info p {
  margin: 0 !important;
}
.r2-info1 p {
  margin: 0 !important;
}
/* 导航栏 */
/* .van-nav-bar {
  background: none !important;
} */
.van-nav-bar .van-nav-bar__left .van-icon {
  color: rgb(0, 0, 0) !important;
  font-size: 18px !important;
}
/* .van-nav-bar__title,
.van-nav-bar__text {
  color: white !important;
} */
/* .van-hairline--bottom::after {
  border: 0 !important;
} */

/* 日历样式 */
.el-date-picker-calendar__header {
  margin: 0 !important;
}
.el-picker-panel-calendar__icon-btn,
.el-date-picker-calendar__header-label,
.el-date-table-calendar th {
  color: white !important;
}
/* .van-search{
  background: none !important;
} */
/* 搜索框 */
.van-search__content {
  background-color: #eff4fb !important;
  border-radius: 10px !important;
}
.van-field__left-icon .van-icon {
  font-size: 22px !important;
  color: #2a4c89 !important;
}
/* 底部导航 */
.nav-item {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  font-size: 12px;
  padding: 8px 0;
  background: white;
  box-shadow: 1px 1px 4px 1px #8f8f8f;
  z-index: 5000;
}
.navs {
  width: 43%;
  text-align: center;
  line-height: 1.5;
  color: #dedede;
}
.navs-a {
  color: #19398C;
}
.nav-item img {
  width: 21px;
  height: 21px;
}
#formInput .van-checkbox__label {
  color: #aaaaaa !important ;
}
#formInput .van-cell {
  background: #eff7fe !important;
  margin-bottom: 10px !important;
}
#manage-login .van-cell {
  background: rgb(239 247 254 / 13%) !important;
}
#manage-login .van-field__label {
  color: white !important;
}
#manage-login .van-field__control {
  color: white !important;
}
#info .van-popup {
  background: none !important;
}
.ad {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}
.ad div {
  flex: 1;
  padding: 5px;
}
.ad img {
  width: 100%;
  border-radius: 5px;
}
/* 进度条颜色 */
.vm-progress.is-info .vm-progress-bar__inner {
  background: linear-gradient(to right, #e75529, #f09a37) !important;
}
.vm-progress-bar__innerText {
  color: #ce4031 !important;
  position: relative;
  top: 20px;
  left: 20px;
}
.vm-progress-bar__innerText::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  bottom: 21px;
  right: 15px;
}
.vm-progress-bar__outer {
  overflow: inherit !important;
}
.address .van-cell {
  font-weight: bold !important;
  font-size: 15px !important;
  color: black !important;
}
.content p {
  padding: 0;
  margin: 5px;
}
.finish .van-cell {
  background: #c6ebfb;
  border: solid 1px white;
  border-radius: 5px;
}
.add-info .van-cell-group {
  background: none;
}
.add-info .van-cell {
  margin-bottom: 10px;
}
section span {
  position: absolute;
  bottom: -50px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  pointer-events: none;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.8);
  animation: animate 8s linear infinite;
  display: block;
}

section span::before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 15%;
  left: 5px;
  width: 30%;
  height: 15%;
  transform: scale(1) rotate(-30deg);
  /* content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.25) translate(-70%, -70%);
  background: radial-gradient(#fff, transparent);
  border-radius: 50%; */
}

@keyframes animate {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    transform: translateY(-2500%);
    opacity: 0;
  }
}
.rule-con p {
  line-height: 2 !important;
  margin: 0 !important;
  font-size: 13px !important;
}

#video1 video::-webkit-media-controls-enclosure {
  display: none !important;
}
#video2 video::-webkit-media-controls-enclosure {
  display: none !important;
}
/* .van-tabs__line{
  background: rgb(123, 138, 115) !important;
} */
.zan {
  text-align: center;
  color: gray;
  margin: 50px 0;
}
.notes p {
  margin: 0;
  font-size: 12px;
}
.notes .title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
}
.tex {
  background: rgb(233, 233, 233) !important;
  width: 94% !important;
  margin: 0 auto;
}
.support {
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
  color: rgb(58, 58, 58);
}
.content img {
  max-width: 100%;
}
</style>
