import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "vant/lib/index.css"
import 'amfe-flexible/index.js'
import Progress from 'vue-multiple-progress'
import $ from 'jquery' // 导入jQuery库
window.$ = window.jQuery = $ // 设置全局变量$为jQuery对象
Vue.use(Progress)
// import vuePicturePreview from 'vue-picture-preview'
// Vue.use(vuePicturePreview)
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
// Vue.use(Viewer)
// import VueWechatTitle from 'vue-wechat-title'; 
// Vue.use(VueWechatTitle)
// 引入字体样式
// import './common/font/font.css';
// import getJSSDK from "./until/getJSSDK";
// Vue.prototype.$share = getJSSDK.getJSSDK
// Vue.config.productionTip = false
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)
// appId
// import minx from './mixins/index'
// Vue.use(minx)

let vueMain = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vueMain

// router.beforeEach((to, from, next) => {
//   window.document.title = to.meta.title == undefined?'高新就业创业':to.meta.title
//   if (to.meta.requireAuth) {
//       let token = Cookies.get('access_token');
//       let anonymous = Cookies.get('user_name');
//       if (token) { 
          
//               next({
//                   path: '/login',
//                   query: {
//                       redirect: to.fullPath
//                   } 
//               })
        
//       }
//   }
//   next();
// })
